export async function useComponentResponse<T>(props: {
  deliveryId?: string
  locale?: string
  vse?: string
  content?: T
}) {
  const content = computed(() => props.content)

  if (content?.value) {
    return content
  }
  const url = `/api/cms/getByKey?locale=${props.locale}&id=${props.deliveryId}&vse=${props.vse ?? ''}`
  const { data } = await useFetch<T>(url, {
    key: url,
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })

  return data
}
